<template>
  <section class="general-container">
    <h2>Contacto</h2>
    <div class="form-container">
      <form method="post" action="" @submit.prevent="validateForm" novalidate="true" autocomplete="off">
        <label id="nameLabel" for="name" :class="{ 'is-invalid': errors.has('name') }">Nombre</label>
        <input id="name" name="name" type="text" tabindex="1" v-model="formData.name" v-validate="'required|alpha_spaces'">
        <label id="emailLabel" for="email" :class="{ 'is-invalid': errors.has('email') }">E-Mail</label>
        <input id="email" name="email" type="email" tabindex="2" v-model="formData.email" v-validate="'required|email'">
        <label for="chasis">Número de chasis</label>
        <input id="chasis" name="chasis" type="text" tabindex="3" v-model="formData.chasis">
        <label for="motor">Número de motor</label>
        <input id="motor" name="motor" type="text" tabindex="4" v-model="formData.motor">
        <label id="messageLabel" for="message" :class="{ 'is-invalid': errors.has('message') }">Mensaje</label>
        <textarea id="message" name="message" type="text" rows="9" tabindex="5" v-model="formData.message" v-validate="'required'"></textarea>
        <transition name="fade">
          <div v-if="showSuccessMessage" class="success-message">
            <p>Muchas gracias, pronto nos pondremos en contacto con usted!</p>
          </div>
        </transition>
        <transition name="fade">
          <div v-if="showErrorMessage" class="error-message">
            <p>Por favor, complete los campos requeridos marcados en rojo</p>
          </div>
        </transition>
        <button type="submit" tabindex="6">Enviar mensaje</button>
      </form>
    </div>
    <div class="general-container--flex">
      <article class="contact-information">
        <h3>Dirección:</h3>
        <p><a href="https://goo.gl/maps/oJ93bFo2RVnB8jX27" target="_blank">Avenida del Libertador 6430 (C1428), Núñez, Capital Federal, Argentina</a></p>
        <h3>Horarios de atención:</h3>
        <p>De <strong>Lunes a Viernes</strong> de <strong>08:30am a 12:00pm</strong> y de <strong>14:00pm a 18:00pm</strong></p>
        <h3>Teléfono (líneas rotativas):</h3>
        <p><a href="tel:01147872626">011 4787-2626</a></p>
        <h3>Email:</h3>
        <p><a href="mailto:ventas@freewaymotors.com.ar?subject=Consulta desde sitio web">ventas@freewaymotors.com.ar</a></p>
        <h3>WhatsApp:</h3>
        <p><a href="https://api.whatsapp.com/send?phone=5491162241258&text=Me%20gustaría%20que%20me%20pasen%20un%20presupuesto%20de%20los%20siguientes%20productos (incluir número de chasis y número de motor):%20" target="_blank">Envianos tu consulta por WhatsApp!</a></p>
      </article>
      <article id="contact-map"></article>
    </div>
  </section>
</template>

<script>
import axios from 'axios';
import gmapsInit from '../gmaps';

const locations = [
  {
    position: {
      lat: -34.5527184,
      lng: -58.4512217,
    },
  },
];

export default {
  name: 'Contacto',
  data() {
    return {
      formData: {
        name: null,
        email: null,
        chasis: null,
        motor: null,
        message: null,
      },
      showSuccessMessage: false,
      showErrorMessage: false,
    }
  },
  beforeCreate() {
    document.body.className = 'contact';
  },
  async mounted() {
    try {
      const google = await gmapsInit();
      const geocoder = new google.maps.Geocoder();
      const map = new google.maps.Map(document.getElementById('contact-map'));

      geocoder.geocode({ address: 'Avenida del Libertador 6591, Núñez, Buenos Aires' }, (results, status) => {
        if (status !== 'OK' || !results[0]) {
          throw new Error(status);
        }

        map.setCenter(results[0].geometry.location);
        map.fitBounds(results[0].geometry.viewport);
      });

      const markers = locations.map(x => new google.maps.Marker({ ...x, map, animation: google.maps.Animation.BOUNCE }));
    } catch (error) {
      console.error(error);
    }
  },
  methods: {
    validateForm() {
      let successMessage = document.querySelector('.success-message');
      let errorMessage = document.querySelector('.error-message');

      this.$validator.validate().then(result => {
        if (!result) {
          this.showErrorMessage = true;
          this.showSuccessMessage = false;
        } else {
          axios.post('https://freewaymotors.com.ar/form/submit.php', {
            'name': this.formData.name,
            'email': this.formData.email,
            'chasis': this.formData.chasis,
            'motor': this.formData.motor,
            'message': this.formData.message,
          })
          .then(function (response) {
            console.log(response);
          })
          .catch(function (error) {
            console.log(error);
          });

          this.showErrorMessage = false;

          setTimeout(() => {
            this.showSuccessMessage = true;

            this.formData.name = null;
            this.formData.email = null;
            this.formData.chasis = null;
            this.formData.motor = null;
            this.formData.message = null;

            this.$validator.reset()
          }, 500);
          
          setTimeout(() => {
            this.showSuccessMessage = false;
          }, 5000);
        }
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.general-container {
  .form-container {
    padding: 0 1.25rem;

    @include grid-media($tablet-neat-grid) {
      padding: 0;
    }
    
    form {
      margin-bottom: 60px;

      label {
        display: inline-block;
        font-size: 20px;
        margin-bottom: 5px;

        &.is-invalid {
          color: #E94B35;
        }
      }

      input,
      textarea {
        font-size: 20px;
        box-sizing : border-box;
        display: block;
        width: 100%;
        border-radius: .25rem;
        border: 1px solid #2E2E2E;
        padding: 10px 24px 10px 15px;
        margin-bottom: 10px;

        &:focus {
          box-shadow: 0 0 1px rgba(0, 0, 0, .2);
        }
        
        &.is-valid {
          border-color: #2E2E2E;
        }

        &.is-invalid {
          border-color: #E94B35;
        }
      }

      input {
        height: 50px;
        margin-bottom: 30px;
      }

      textarea {
        height: 100%;
        min-height: 200px;
        resize: vertical;
      }

      [aria-required] {
        background: url('../assets/contact-form-required-icon.svg') no-repeat top right;
      }

      .error-message,
      .success-message {
        background: #E94B35;
        color: white;
        text-align: center;
        border-radius: .25em;
        padding: 16px;
        margin: 20px 0;
      }

      .success-message {
        background: #20BC7E;	
      }

      button {
        background: #2E2E2E;
        width: 100%;
        height: 50px;
        color: #FFF;
        text-transform: uppercase;
        border-radius: 3px;
        cursor: pointer;

        &:hover {
          background: #20BC7E;
        }

        &:active {
          transform: scale(.9);
        }
      }
    }
  }

  .general-container--flex {
    display: flex;
    flex-direction: column;
    color: #2E2E2E;
    padding: 0 1.25rem;

    @include grid-media($tablet-neat-grid) {
      flex-direction: row;
      padding: 0;
    }

    p {
      line-height: 1.5;
    }

    .contact-information,
    .contact-map {
      width: 100%;
      
      @include grid-media($tablet-neat-grid) {
        width: 50%;
      }
    }

    .contact-information {
      padding-right: 1rem;

      p {
        margin-bottom: 2.5rem;
      }
    }

    #contact-map {
      width: 100%;
      height: 550px;

      @include grid-media($tablet-neat-grid) {
        width: 50%;
      }
    }
  }
}
</style>
