<template>
  <section class="general-container">
    <h1>Mercedes Benz</h1>
    <table>
      <thead>
        <tr>
          <th>Código</th>
          <th>Descripción</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(mercedesBenzSparePart, index) in mercedesBenzSpareParts">
          <td data-title='Código'>{{ mercedesBenzSparePart.Code }}</td>
          <td data-title='Descripción'>{{ mercedesBenzSparePart.Description }}</td>
        </tr>
      </tbody>
    </table>

    <h1>BMW</h1>
    <table>
      <thead>
        <tr>
          <th>Código</th>
          <th>Descripción</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(bmwSparePart, index) in bmwSpareParts">
          <td data-title='Código'>{{ bmwSparePart.Code }}</td>
          <td data-title='Descripción'>{{ bmwSparePart.Description }}</td>
        </tr>
      </tbody>
    </table>

    <h1>Varios</h1>
    <table>
      <thead>
        <tr>
          <th>Código</th>
          <th>Descripción</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(variousSparePart, index) in variousSpareParts">
          <td data-title='Código'>{{ variousSparePart.Code }}</td>
          <td data-title='Descripción'>{{ variousSparePart.Description }}</td>
        </tr>
      </tbody>
    </table>
  </section>
</template>

<script>
import { db } from '../firebase';

export default {
  name: 'Productos',
  data() {
    return {
      mercedesBenzSpareParts: {},
      bmwSpareParts: {},
      variousSpareParts: {},
    };
  },
  firebase: {
    mercedesBenzSpareParts: {
      source: db.ref('mercedes-benz'),
    },
    bmwSpareParts: {
      source: db.ref('bmw'),
    },
    variousSpareParts: {
      source: db.ref('various'),
    },
  },
  beforeCreate() {
    document.body.className = 'products';
  }
};
</script>

<style lang="scss" scoped>
table {
  display: block;
  width: 100%;
  color: white;
  background-color: $light-gray;

  @include grid-media($tablet-neat-grid) {
    display: table;
  }

  thead {
    display: none;

    @include grid-media($tablet-neat-grid) {
      display: table-header-group;
    }
  }

  tbody {
    @include grid-media($tablet-neat-grid) {
      display: table-row-group;
    }

    &:nth-child(2n+1) td {
      background-color: #242e39;
    }

    tr td {
      border: none;

      @include grid-media($tablet-neat-grid) {
        border: 1px solid #28333f;
      }
    }
  }
}

tr,
td,
tbody {
  display: block;
}

tr {
  @include grid-media($tablet-neat-grid) {
    display: table-row;
  }

  &:nth-child(2n+2) {
    td {
      @include grid-media($tablet-neat-grid) {
        background-color: darken(#2C3845, 4%);
      }
    }
  }

  td {
    padding: 10px 10px;
    text-align: center;

    @include grid-media($tablet-neat-grid) {
      display: table-cell;
      text-align: left;
      border: 1px solid darken(#2C3845, 2%);
      padding: 16px;
    }

    &:before {
      content: attr(data-title);
      color: lighten(#2C3845, 35%);
      text-transform: uppercase;
      font-size: 1.4rem;
      padding-bottom: 10px;
      display: block;

      @include grid-media($tablet-neat-grid) {
        display: none;
      }
    }
  }

  th {
    font-weight: $font-weight-bold;
    text-align: left;
    padding: 15px;

    @include grid-media($tablet-neat-grid) {
      display: table-cell;
    }
  }
}

thead {
  th {
    background-color: #202932;
    color: #fff;
    border: 1px solid #202932;
  }
}
</style>
